import React from "react"
import BookTemplate from "../components/common/BookTemplate"
import MuckleminsImg from "../components/common/MuckleminsImg"
import MukleminsDescription from "../components/MukleminsDescription"
import BlueGradientImage from "../components/common/BlueGradientImage"
import MukleminsTshirt from "../components/common/MukleminsTshirt"
import {
  ScGridRow,
  ScGridCol,
  ScH1,
  ScText,
  ScExternalLinkButton,
} from "../components/StyledComponents/elements"
import SEO from "../components/seo"

function Muklemins() {
  return (
    <div>
      <SEO title="Muklemins" />
      <BookTemplate
        BookImg={MuckleminsImg}
        BookDescription={MukleminsDescription}
      />
      <BlueGradientImage>
        <ScGridRow
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <ScGridCol span={3.5}>
            <ScGridRow j>
              <MukleminsTshirt />
            </ScGridRow>
          </ScGridCol>
          <ScGridCol span={3.5}>
            <ScGridRow>
              <ScH1>Check out Merch on Etsy</ScH1>
            </ScGridRow>
            <ScGridRow>
              <ScText fontSize="data-text">
                One of the main benefits of using Lorem Ipsum is that it can be
                easily generated, and it takes the pressure off designers to
                create meaningful text.
              </ScText>
            </ScGridRow>
            <ScGridRow>
              <ScExternalLinkButton
                width="150px"
                color="primary"
                href="https://www.etsy.com/listing/615327140/short-sleeve-kids-t-shirt?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=muklemins&ref=sr_gallery-1-2&organic_search_click=1&col=1"
              >
                Check it out
              </ScExternalLinkButton>
            </ScGridRow>
          </ScGridCol>
        </ScGridRow>
      </BlueGradientImage>
    </div>
  )
}

export default Muklemins
