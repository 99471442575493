import React from "react"
import {
  ScGridRow,
  ScH1,
  ScText,
  ScH3,
  ScExternalLinkButton,
} from "./StyledComponents/elements"

function MukleminsDescription() {
  return (
    <div>
      <ScGridRow>
        <ScH1>The Muklemins: Camping In</ScH1>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          Mother announces, “We are all Camping In tonight…Camping In the living
          room.” The kids gather blankets, a flashlight, pillows and make a fort
          over the couch and begin to sing…
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          Zoe, Chloe and I, <br />
          Flashlights light the sky, <br />
          Shooting stars and butterflies, <br />
          Across the ceiling, they fly.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          We are camping in,
          <br />
          We are camping in,
          <br />
          Camping in the living room,
          <br />
          We are camping in.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          So, whenever you get bored, just go camping in your living room, in
          your classroom, in an airplane, in a hotel room, in your parent's car,
          even in the hospital. Wherever you are just “Login on Your Noggin,”
          while camping in your own space, and you will start creating wonderful
          ideas and the whole world is yours. Remember your brain needs exercise
          just like your body does.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">Sing-a-long with the Muklemins…</ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          It dreams while you’re sleeping and it runs all day long. Your brain
          needs exercise to make it really strong, Use your imagination to
          create a new game. It’s way better than any device that you can name.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          So you better… Login on your noggin… YEAH! <br />
          Your noggin, your noggin , your na...na...na…na noggin.
          <br />
          Your noggin, your noggin , your na...na...na…na noggin.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          The audio book contains full text the two fabulous and fun songs
          performed by JabStar
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScH3>Order on Amazon!</ScH3>
      </ScGridRow>
      <ScGridRow justifyContent="start">
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Muklemins-Camping-Songs-performed-JabStar/dp/0978648854/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1578200115&sr=8-1"
        >
          PAPERBACK
        </ScExternalLinkButton>
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Muklemins-Camping-Cricket-Casey/dp/0978648862/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1578200115&sr=8-1"
        >
          HARDBACK
        </ScExternalLinkButton>
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/The-Muklemins-Camping-In/dp/B00RWALWSU/ref=tmm_aud_swatch_0?_encoding=UTF8&qid=1578200115&sr=8-1"
        >
          AUDIO BOOK
        </ScExternalLinkButton>
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Muklemins-Camping-Cricket-Casey-ebook/dp/B00I13I60O/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1578200115&sr=8-1"
        >
          KINDLE
        </ScExternalLinkButton>
      </ScGridRow>
    </div>
  )
}

export default MukleminsDescription
