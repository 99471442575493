import { graphql } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ScHeaderImage = styled(Img)`
  max-width: 350px;
  width: 100%;
`

function MukleminsTshirt() {
  const img = useStaticQuery(graphql`
    {
      fileName: file(relativePath: { eq: "muklemins_shirt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <ScHeaderImage fluid={img.fileName.childImageSharp.fluid} />
}

export default MukleminsTshirt
